import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box';
import { Pill } from 'components/Pill';

export function SearchFiltersSidebarFilterPill({
  text,
  clearFilter,
}: {
  text: string;
  clearFilter: () => void;
}) {
  return (
    <Box data-qa-id="filter-pill" py="4px" px="2px" maxWidth="50%">
      <Pill
        onClick={clearFilter}
        title={sprintf(getText(`Clear %(option)s filter`), {
          option: text,
        })}
        text={text}
      />
    </Box>
  );
}
