import { Fragment, useMemo } from 'react';
import ReactSelect, { Props } from 'react-select';

import { DropdownIndicator } from 'components/Select/DropdownIndicator';
import { MultiValueRemove } from 'components/Select/MultiValueRemove';
import { ClientOnly } from 'containers/ClientOnly';

import { ClearIndicator } from './ClearIndicator';
import { StyledSelectInput, selectStyles } from './Select.styled';
import { OptionType } from './Select.types';

type CustomProps = {
  'aria-label'?: string;
  label?: string;
  disabled?: boolean;
  variant?: 'grey';
  height?: number;
  isClearable?: boolean;
  isSearchable?: boolean;
  noMargin?: boolean;
  menuPosition?: 'fixed' | 'absolute';
  'data-qa-id'?: string;
  // Overriden for a simpler API
  value?: OptionType['value'];
};

export function Select({
  name,
  label,
  value,
  options,
  disabled,
  variant,
  height,
  className,
  isClearable,
  isSearchable,
  placeholder,
  menuPosition,
  'data-qa-id': qaId,
  ...props
}: CustomProps & Omit<Props<OptionType, false>, 'value'>) {
  const classNames = useMemo(() => {
    const names = [];

    names.push(qaId ? `qa-id-${qaId}` : null);
    names.push(className || null);

    return names.filter(Boolean).join(' ');
  }, [qaId, className]);

  const Container = label ? 'label' : Fragment;

  const components = {
    ClearIndicator,
    MultiValueRemove,
    ...[disabled ? DropdownIndicator : undefined],
  };

  return (
    <ClientOnly
      placeholder={
        <StyledSelectInput
          name={name}
          options={
            // Assume we don't use option groups
            (options as ReadonlyArray<OptionType & { disabled?: boolean }>) ||
            []
          }
          value={value || ''}
          className={classNames}
          height={height}
        />
      }
    >
      <Container>
        <ReactSelect<OptionType, false>
          classNamePrefix="react-select"
          maxMenuHeight={250}
          aria-label={label}
          name={name}
          options={options}
          // Assume we don't use option groups
          value={(options as ReadonlyArray<OptionType> | undefined)?.filter(
            ({ value: v }) => v === value,
          )}
          isDisabled={disabled}
          isSearchable={isSearchable}
          isClearable={isClearable || false}
          className={classNames}
          placeholder={placeholder || getText('Select...')}
          components={components}
          menuPosition={menuPosition}
          styles={selectStyles<OptionType, false>({ height })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Container>
    </ClientOnly>
  );
}
