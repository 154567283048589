import { useState } from 'react';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { Box } from 'components/Box';
import { LegacyButton as Button } from 'components/Button/LegacyButton';
import { Text } from 'components/Text/Text';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { colors, fontWeights, transition } from 'theme/theme';
import { isNotEmpty } from 'utils/functional';

import { SearchFiltersSidebarFilterPill } from './SearchFiltersSidebarFilterPill';
import { searchFiltersSidebarMapFacetToPill } from './searchFiltersSidebarMapFacetToPill';

const ExpandButton = styled(Box)`
  color: ${colors.brandBlue} !important;
  transition: all ${transition};

  &:focus,
  &:hover {
    background: ${colors.brandBlue};
    color: white !important;
  }
`;

type SelectedFiltersProps = {
  'data-qa-id'?: string;
  facets: SearchFacet[];
  filtersById: SearchFiltersById;
  jobSynonym?: { id: string; name: string };
  clearFilter: (facetId: SearchFacetId, option: string) => void;
  setFilter: (
    facetId: SearchFacetId,
    filter:
      | string
      | { endsGT: string | undefined; startsLT: string | undefined }
      | null
      | undefined,
  ) => void;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  searchLocation: SearchLocation | null | undefined;
  setJobSynonym: ((jobSynonym: string) => void) | undefined;
  setSearchLocation:
    | ((
        searchLocation: SearchLocation | null | undefined,
        shouldEnqueueSearch?: boolean | null,
      ) => void)
    | undefined;
  searchLocationActive: boolean;
};

export function SearchFiltersSidebarSelectedFilters({
  filtersById,
  facets,
  jobSynonym,
  clearFilter,
  setFilter,
  selectAllSearchFilterOptions,
  searchLocation,
  setJobSynonym,
  setSearchLocation,
  searchLocationActive,
  ...props
}: SelectedFiltersProps) {
  const [expanded, setExpanded] = useState(false);

  const pills = facets
    .map((facet) =>
      searchFiltersSidebarMapFacetToPill({
        facet,
        filtersById,
        setFilter,
        searchLocation,
        searchLocationActive,
        clearFilter,
        selectAllSearchFilterOptions,
        jobSynonym,
      }),
    )
    .reduce((acc, facet) => acc.concat(facet), []);

  const maxFilters = searchLocationActive ? 3 : 4;

  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box display="flex" flexWrap="wrap" {...props}>
      {searchLocationActive && (
        <SearchFiltersSidebarFilterPill
          key="location"
          text={
            searchLocation && isNotEmpty(searchLocation.text)
              ? sprintf(getText('Location: %(location)s'), {
                  location: searchLocation.text,
                })
              : getText('Location')
          }
          clearFilter={() => setSearchLocation?.(null)}
        />
      )}

      {pills.filter((pill, index) => expanded || index < maxFilters)}

      {pills.length > maxFilters && !expanded && (
        <Box py="4px" px="2px">
          <ExpandButton
            component={Button}
            onClick={() => setExpanded(true)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={32}
            width={32}
            borderRadius="50%"
            boxShadow={`0 0 0 1px ${colors.brandBlue} inset`}
            letterSpacing="0.05em"
          >
            <Text styleAs="bodySmall" fontWeight={fontWeights.semiBold}>
              +{pills.length - maxFilters}
            </Text>
          </ExpandButton>
        </Box>
      )}
    </Box>
  );
}
