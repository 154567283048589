import { format, isValid, parseISO } from 'date-fns';
import { enUS, es, pt } from 'date-fns/locale';
import debounce from 'lodash.debounce';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  Ref,
  forwardRef,
} from 'react';
import { registerLocale } from 'react-datepicker';
import styled from 'styled-components';

import { Input } from 'components/Input/Input';
import { InputProps } from 'components/Input/InputProps';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';
import { colors } from 'theme/theme';
import { useLoadBundleCssOnce } from 'zustand-stores/loadCssBundleStore';

import { DatePickerPopper } from './DatePicker.styled';
import { DatePickerLoadable } from './DatePickerLoadable';

registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('pt', pt);

type Props = {
  className?: string;
  id?: string;
  name?: string;
  required?: boolean;
  value: string | null | undefined;
  placeholder?: string;
  onChange: (date: string | null) => void;
  hasError?: boolean;
  disablePastDates?: boolean;
  strictParsing?: boolean;
  dateFormat?: string;
  isTimeZoneAware?: boolean;
  showMonthYearPicker?: boolean;
  'data-qa-id'?: string;
};

function createLocalDateString(date: Date | null) {
  if (date === null) {
    return null;
  }

  const options = {
    hour12: false,
  };

  return date.toLocaleDateString('sv', options).replace(/\//g, '-');
}

const CustomInput = forwardRef(
  (
    {
      inputProps,
      value: fooValue,
      onChange,
      onClick,
      onKeyDown,
    }: {
      inputProps: InputProps;
      value?: string;
      onChange?: ChangeEventHandler;
      onClick?: MouseEventHandler;
      onKeyDown?: KeyboardEventHandler;
    },
    ref: Ref<HTMLInputElement>,
  ) => (
    <Input
      inputProps={{
        ...inputProps,
        onChange,
        onClick,
        ref,
        value: fooValue,
        onKeyDown,
      }}
      affixVariant="transparent"
      suffix={<InputIconAffix iconName="calendar" />}
    />
  ),
);

export const DatePicker = styled(
  ({
    className,
    value,
    onChange,
    hasError,
    disablePastDates,
    strictParsing = true,
    dateFormat,
    isTimeZoneAware,
    showMonthYearPicker = false,
    ...rest
  }: Props) => {
    const datetimeProps = {
      ...rest,
      qaId: rest['data-qa-id'],
      autoComplete: 'off',
    };

    useLoadBundleCssOnce('reactDatepicker');

    const onChangeDebounced = debounce(onChange, 200);
    const parsedValue = value ? parseISO(value) : null;
    return (
      <DatePickerLoadable
        calendarClassName={className}
        customInput={<CustomInput inputProps={datetimeProps} />}
        shouldCloseOnSelect
        locale={CURRENT_LOCALE}
        name={datetimeProps.name}
        dateFormat={dateFormat || 'P'}
        showMonthYearPicker={showMonthYearPicker}
        minDate={disablePastDates ? new Date() : undefined}
        popperContainer={DatePickerPopper}
        strictParsing={strictParsing}
        selected={parsedValue}
        onChange={(selectedDate) => {
          if (selectedDate && isValid(selectedDate) && isTimeZoneAware) {
            onChangeDebounced(createLocalDateString(selectedDate));
          } else if (selectedDate && isValid(selectedDate)) {
            const dateString = format(selectedDate, 'yyyy-MM-dd');
            onChangeDebounced(dateString);
          } else {
            onChangeDebounced(null);
          }
        }}
      />
    );
  },
)`
  /* stylelint-disable-next-line selector-class-pattern */
  .react-datepicker__day--selected {
    background: ${colors.brandBlue};
  }
`;
